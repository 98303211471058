import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Heading,
  Input,
  List,
  ListItem,
  useToast,
  VStack,
  Text,
  Tag,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { useRegistrarStore } from "./useRegistrarStore";
import { addStudentToQueue } from "../../services/dataService";
import studentData from "../../data/data.json";
import { StudentArabicStatus } from "../photobooth/Photobooth";
import { StudentStatus } from "../../services/dataService";

const Registrar = () => {
  const [stid, setStid] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  interface Student {
    Student_ID: number;
    Name_in_Arabic: string;
    College?: string;
    Major?: string;
  }

  const [selectedStudent, setSelectedStudent] = useState<Student | null>(null);
  const { queue, loadQueue } = useRegistrarStore();
  const toast = useToast();

  enum RoomName {
    Room207 = "207",
    Room208 = "208",
    Room209 = "209",
  }

  const getRoomName = (value: number): RoomName | undefined => {
    switch (value) {
      case 1:
        return RoomName.Room207;
      case 2:
        return RoomName.Room208;
      case 3:
        return RoomName.Room209;
      default:
        return undefined;
    }
  };

  const findStudentData = (stid: string) => {
    return studentData.find((student) => student.Student_ID === Number(stid));
  };

  useEffect(() => {
    loadQueue();
    const interval = setInterval(() => {
      loadQueue();
    }, 10000);
    return () => clearInterval(interval);
  }, [loadQueue]);

  const validateStudentId = (id: string) => {
    const isValid = /^[2]\d{8}$/.test(id);
    if (!isValid) {
      toast({
        title: "خطأ في الإدخال",
        description: "رقم الطالب يجب أن يكون 9 أرقام ويبدأ بالرقم 2",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-right",
      });
    }
    return isValid;
  };

  const handleAddStudent = () => {
    if (!validateStudentId(stid)) return;

    const studentInfo = findStudentData(stid);
    setSelectedStudent(studentInfo || { Student_ID: Number(stid), Name_in_Arabic: "غير معروف" });
    setIsModalOpen(true);
  };

  const confirmAddStudent = async () => {
    setIsModalOpen(false);
    try {
      await addStudentToQueue(stid);
      setStid("");
      loadQueue();
      toast({
        title: "تمت العملية",
        description: "تم إضافة الطالب بنجاح",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-right",
      });
    } catch (error) {
      toast({
        title: "خطأ",
        description: "الطالب دخل من قبل",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };

  return (
    <Box textAlign="center" p={6}>
      <VStack spacing={6} w="100%" maxW="500px" mx="auto">
        <Heading size="lg" color="kfupm.green">
          تسجيل الطلاب
        </Heading>
        <Input
          placeholder="أدخل رقم الطالب"
          value={stid}
          onChange={(e) => setStid(e.target.value)}
          size="lg"
          variant="outline"
          focusBorderColor="kfupm.green"
          bg="white"
          color="kfupm.darkGray"
          _placeholder={{ color: "kfupm.darkGray" }}
        />
        <Button onClick={handleAddStudent} colorScheme="green" size="lg" w="100%">
          إضافة إلى قائمة الانتظار
        </Button>
      </VStack>

      <Heading size="md" color="kfupm.petrol" mt={10} mb={4} textAlign="center">
        قائمة الانتظار الحالية
      </Heading>
      <Box maxW="600px" mx="auto">
        <List spacing={3} bg="kfupm.lightGray" p={4} borderRadius="md" boxShadow="md">
          {queue.map((student) => {
            const studentInfo = findStudentData(student.stid);
            return (
              <ListItem
                key={student.id}
                fontSize="lg"
                color="kfupm.darkGray"
                borderBottom="1px solid"
                borderColor="kfupm.stone"
                p={3}
                display="flex"
                flexDirection="column"
                alignItems="start"
              >
                <Box>
                  <Text fontWeight="bold">
                    {studentInfo ? studentInfo.Name_in_Arabic : student.stid}
                  </Text>
                  <Text>
                    رقم الطالب: {student.stid}{" "}
                    <Tag colorScheme={student.status === "in_room" ? "green" : "purple"}>
                      {StudentArabicStatus[student.status as StudentStatus]}
                    </Tag>
                  </Text>
                  {studentInfo && (
                    <>
                      <Text color="gray.600">الكلية: {studentInfo.College || "غير محدد"}</Text>
                      <Text color="gray.600">التخصص: {studentInfo.Major || "غير محدد"}</Text>
                    </>
                  )}
                </Box>
                {student.room_id && (
                  <Box fontSize="md" color="kfupm.petrol" fontWeight="bold" mt={2}>
                    غرفة {getRoomName(student.room_id)}
                  </Box>
                )}
              </ListItem>
            );
          })}
        </List>
      </Box>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>تأكيد بيانات الطالب</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedStudent ? (
              <>
                <Text fontWeight="bold" mb={2}>
                  الاسم: {selectedStudent.Name_in_Arabic || "غير معروف"}
                </Text>
                <Text>رقم الطالب: {selectedStudent.Student_ID}</Text>
                <Text>الكلية: {selectedStudent.College || "غير محدد"}</Text>
                <Text>التخصص: {selectedStudent.Major || "غير محدد"}</Text>
              </>
            ) : (
              <Text>لا توجد بيانات للطالب</Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" onClick={confirmAddStudent} mr={3}>
              تأكيد
            </Button>
            <Button variant="ghost" onClick={() => setIsModalOpen(false)}>
              إلغاء
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Registrar;
