import { Box, Text } from "@chakra-ui/react";
import packageJson from "../../../package.json";

function Footer() {
  return (
    <Box
      bg="gray.300"
      py={4}
      textAlign="center"
      position="fixed"
      bottom={0}
      width="100%"
      zIndex={1000}
    >
      <Text fontSize="sm">
        صنع بكل <Text as="span" color="green.500">💚</Text> من قبل فريق البرمجيات في الظهران KFUPM
      </Text>
      <Text fontSize="xs">كل الحقوق محفوظة إلى لجنة الكتاب السنوي</Text>
      <Text fontSize="xs">الإصدار: {packageJson.version}</Text>
    </Box>
  );
}

export default Footer;
