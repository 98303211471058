import { Box, Flex, Image, Heading } from "@chakra-ui/react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <Box
      bg="gray.200"
      w="100%"
      p={4}
      boxShadow="md"
      position="sticky"
      top={0}
      zIndex={1000}
    >
      <Flex maxW="1200px" mx="auto" align="center">
        <Link to="/">
          <Image
            src="/logo.png"
            alt="KFUPM Yearbook Logo"
            h={12}
            mr={4}
          />
        </Link>
      </Flex>
    </Box>
  );
}

export default Header;
