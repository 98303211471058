import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Flex,
  Heading,
  Text,
  VStack,
  Button,
  Tag,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { usePhotoboothStore } from "./stores/usePhotoboothStore";
import {
  removeStudentFromRoom,
  updateTableEntry,
} from "../../services/dataService";
import { StudentStatus } from "../../services/dataService";
import studentData from "../../data/data.json";
import theme from "../../theme/Theme";

export const StudentArabicStatus: { [key in StudentStatus]: string } = {
  [StudentStatus.Waiting]: "في الانتظار",
  [StudentStatus.InRoom]: "في الغرفة",
  [StudentStatus.Completed]: "مكتمل",
  [StudentStatus.Exited]: "طلع",
};

const Photobooth = () => {
  const { roomId } = useParams<{ roomId: string }>();
  const { roomData, fetchRoomData } = usePhotoboothStore();
  const [loadingStudentIds, setLoadingStudentIds] = useState<number[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedStudentId, setSelectedStudentId] = useState<number | null>(
    null
  );

  type College = "ES" | "IM" | "ED" | "ARC" | "CS" | "CM" | "PG" | "Master";
  const CollegeColors = {
    ES: theme.colors.kfupm.engineering,
    IM: theme.colors.kfupm.businessSchool,
    ED: theme.colors.kfupm.design,
    ARC: theme.colors.kfupm.design,
    CS: theme.colors.kfupm.computing,
    CM: theme.colors.kfupm.chemicals,
    PG: theme.colors.kfupm.petroleumEngineering,
    Master: theme.colors.kfupm.graduate,
  };

  const findStudentData = (stid: string) => {
    return studentData.find((student) => student.Student_ID === Number(stid));
  };

  useEffect(() => {
    if (roomId) fetchRoomData(roomId);

    const interval = setInterval(() => {
      if (roomId) {
        fetchRoomData(roomId);
      }
    }, 30000);

    return () => clearInterval(interval);
  }, [fetchRoomData, roomId]);

  const handleStudentComplete = async () => {
    if (selectedStudentId === null) return;
    setLoadingStudentIds((prev) => [...prev, selectedStudentId]);

    try {
      await removeStudentFromRoom(Number(roomId), selectedStudentId);
      if (roomId) {
        await fetchRoomData(roomId);
      }
    } catch (error) {
      console.error("Failed to update student status:", error);
    } finally {
      setLoadingStudentIds((prev) =>
        prev.filter((id) => id !== selectedStudentId)
      );
      setSelectedStudentId(null);
      onClose();
    }
  };

  const handleEnterRoom = async (studentId: number) => {
    try {
      await updateTableEntry("queue", studentId, {
        in_room_at: new Date().toISOString(),
      });
      if (roomId) fetchRoomData(roomId);
    } catch (error) {
      console.error("Failed to update entry time:", error);
    }
  };

  const calculateTimeSpent = (inRoomAt: string) => {
    const inRoomDate = new Date(inRoomAt);
    const now = new Date();
    const diffMs = now.getTime() - inRoomDate.getTime();
    const minutes = Math.floor(diffMs / 60000) - 180;
    const seconds = Math.floor((diffMs % 60000) / 1000);
    return { minutes, display: `${minutes}m ${seconds}s` };
  };

  return (
    <VStack spacing={8} p={6} bg="gray.100" minH="100vh">
      <Heading size="lg" color="kfupm.green" mb={4}>
        غرفة التصوير {roomId}
      </Heading>

      <Flex w="100%" flexDirection="column" alignItems="center">
        {roomData.students.map((student) => {
          const timeSpent = calculateTimeSpent(student.in_room_at!);
          const isOverTime = timeSpent.minutes > 9;
          const isLoading = loadingStudentIds.includes(Number(student.stid));
          const studentInfo = findStudentData(student.stid);
          const collegeColor = studentInfo
            ? CollegeColors[studentInfo.College as College] || "gray"
            : "gray";

          return (
            <Box
              key={student.id}
              w="90%"
              bg={isOverTime ? "red.400" : "green.200"}
              color="black"
              p={4}
              borderRadius="md"
              boxShadow="lg"
              mb={4}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <VStack align="start" spacing={2}>
                <Text fontSize="xl" fontWeight="bold">
                  {studentInfo
                    ? studentInfo.Name_in_Arabic
                    : `رقم الطالب: ${student.stid}`}
                </Text>
                <Tag colorScheme={"black"} fontSize="md">
                  رقم الطالب {student.stid}
                </Tag>
                <Tag
                  bg={collegeColor}
                  color="white"
                  fontSize="md"
                  fontWeight="bold"
                  borderRadius="full"
                  px={3}
                  py={1}
                >
                  {studentInfo ? studentInfo.College : "غير محدد"}
                </Tag>
                <Text fontSize="md" color="gray.600">
                  {studentInfo
                    ? `التخصص: ${studentInfo.Major}`
                    : "التخصص: غير محدد"}
                </Text>

                {student.in_room_at ? (
                  <Text>الوقت المستغرق: {timeSpent.display}</Text>
                ) : "انتظر الطالب ياحلو"}
                <Text>
                  الحالة: {StudentArabicStatus[student.status as StudentStatus]}
                </Text>
              </VStack>
              <VStack>
                {student.in_room_at ? (
                  <Tag colorScheme="green" size="lg" fontWeight="bold">
                    دخل
                  </Tag>
                ) : (
                  <Button
                    colorScheme="teal"
                    onClick={() => handleEnterRoom(Number(student.stid))}
                  >
                    دخل
                  </Button>
                )}
                <Button
                  colorScheme="blue"
                  onClick={() => {
                    setSelectedStudentId(Number(student.stid));
                    onOpen();
                  }}
                  isLoading={isLoading}
                  isDisabled={isLoading}
                >
                  مكتمل
                </Button>
              </VStack>
            </Box>
          );
        })}
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>تأكيد الإكمال</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>هل أنت متأكد أنك تريد تحديد هذا الطالب كمكتمل؟</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" onClick={handleStudentComplete} mr={3}>
              تأكيد
            </Button>
            <Button variant="ghost" onClick={onClose}>
              إلغاء
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default Photobooth;
