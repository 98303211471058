// App.tsx
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Registrar from "./features/registrar/Registrar";
import Screen from "./features/screen/Screen";
import Photobooth from "./features/photobooth/Photobooth";
import Admin from "./features/admin/Admin";
import Home from "./features/home/Home";
import Header from "./features/header/Header";
import Footer from "./features/footer/Footer";
import "./App.css";

function App() {
  return (
    <Router>
      <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
        <Header />
        <div style={{ flex: 1, paddingBottom: "60px" }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/registrar" element={<Registrar />} />
            <Route path="/screen" element={<Screen />} />
            <Route path="/photobooth/:roomId" element={<Photobooth />} />
            <Route path="/admin" element={<Admin />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
