// /features/photobooth/stores/usePhotoboothStore.ts
import { create } from "zustand";
import { fetchTableData, updateTableEntry } from "../../../services/dataService";

interface Student {
  id: string;
  stid: string;
  status: string;
  in_room_at?: string;
}

interface RoomData {
  id: string;
  room_name: string;
  students: Student[];
}

interface PhotoboothStore {
  roomData: RoomData;
  fetchRoomData: (roomId: string) => Promise<void>;
  setRoomData: (updateFn: (prevRoomData: RoomData) => RoomData) => void; // New method to optimistically update room data
}

export const usePhotoboothStore = create<PhotoboothStore>((set) => ({
  roomData: { id: "", room_name: "", students: [] },

  fetchRoomData: async (roomId) => {
    try {
      const rooms = await fetchTableData("rooms");
      const queue = await fetchTableData("queue");
      const room = rooms.find((r: any) => r.id.toString() === roomId);
      if (room) {
        const studentsInRoom = queue.filter(
          (student) => student.room_id === room.id && student.status === "in_room"
        );
        set({
          roomData: {
            id: room.id,
            room_name: room.room_name,
            students: studentsInRoom,
          },
        });
      }
    } catch (error) {
      console.error("Failed to fetch room data:", error);
    }
  },

  // New setRoomData method for local optimistic updates
  setRoomData: (updateFn) => {
    set((state) => ({
      roomData: updateFn(state.roomData),
    }));
  },
}));
