import { useState } from "react";
import {
  Box,
  Heading,
  Input,
  Button,
  VStack,
  Text,
  Link,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "./stores/useAuthStore"; // Import the auth store

const Home = () => {
  const [inputPassword, setInputPassword] = useState("");
  const correctPassword = process.env.REACT_APP_HOME_PASSWORD || "";
  const { isAuthenticated, login } = useAuthStore();
  const navigate = useNavigate();

  const handlePasswordSubmit = () => {
    if (inputPassword === correctPassword) {
      login(); // Update auth store to set the user as authenticated
    } else {
      alert("Incorrect password. Please try again.");
      setInputPassword("");
    }
  };

  return (
    <VStack spacing={8} p={6} bg="gray.100" minH="100vh">
      <Heading size="lg" color="kfupm.green">
        الصفحة الرئيسية
      </Heading>

      {!isAuthenticated ? (
        <VStack spacing={4}>
          <Text color="black">أدخل كلمة المرور للوصول إلى الصفحات المتاحة:</Text>
          <Input
            type="password"
            placeholder="كلمة المرور"
            value={inputPassword}
            onChange={(e) => setInputPassword(e.target.value)}
            width="300px"
            color="black"
            _placeholder={{ color: "black" }}
          />
          <Button colorScheme="teal" onClick={handlePasswordSubmit}>
            دخول
          </Button>
        </VStack>
      ) : (
        <VStack spacing={6} align="start">
          <Heading size="md" color="kfupm.petrol">
            القائمة الرئيسية
          </Heading>
          <Link
            onClick={() => navigate("/screen")}
            color="blue.500"
            fontSize="lg"
          >
            شاشة الانتظار والغرف
          </Link>
          <Link
            onClick={() => navigate("/registrar")}
            color="blue.500"
            fontSize="lg"
          >
            صفحة التسجيل
          </Link>
          <Heading size="sm" color="kfupm.petrol" mt={4}>
            غرف التصوير
          </Heading>
          <VStack align="start">
            <Link onClick={() => navigate("/photobooth/1")} color="blue.500">
              غرفة التصوير 207
            </Link>
            <Link onClick={() => navigate("/photobooth/2")} color="blue.500">
              غرفة التصوير 208
            </Link>
            <Link onClick={() => navigate("/photobooth/3")} color="blue.500">
              غرفة التصوير 209
            </Link>
          </VStack>
        </VStack>
      )}
    </VStack>
  );
};

export default Home;
