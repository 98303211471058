// /features/admin/Admin.tsx
import { useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  VStack,
  Text,
  Button,
  Select,
  HStack,
} from "@chakra-ui/react";
import { useAdminStore } from "./stores/useAdminStore";
import { StudentStatus, RoomStatus } from "../../services/dataService";

const Admin = () => {
  const {
    rooms,
    queue,
    loadRooms,
    loadQueue,
    updateQueueStatus,
    updateRoomStatus,
    removeRoomStudent,
  } = useAdminStore();

  useEffect(() => {
    loadRooms();
    loadQueue();
  }, [loadRooms, loadQueue]);

  return (
    <VStack spacing={8} p={6} bg="gray.100" minH="100vh">
      <Heading size="lg" color="kfupm.green" mb={4}>
        لوحة تحكم المسؤول
      </Heading>

      {/* Queue Management Section */}
      <Box w="100%" bg="white" p={4} borderRadius="md" boxShadow="lg">
        <Heading size="md" color="kfupm.petrol" mb={4} textAlign="center">
          إدارة قائمة الانتظار
        </Heading>
        <VStack spacing={3} align="stretch">
          {queue.map((student) => (
            <Flex
              key={student.id}
              align="center"
              justify="space-between"
              p={2}
              bg="gray.100"
              borderRadius="md"
            >
              <Text fontSize="lg" color="black">
                {student.stid}
              </Text>
              <Select
                value={student.status}
                onChange={(e) =>
                  updateQueueStatus(student.id, e.target.value as StudentStatus)
                }
                width="auto"
                color="black"
              >
                <option value={StudentStatus.Waiting}>في الانتظار</option>
                <option value={StudentStatus.InRoom}>في الغرفة</option>
                <option value={StudentStatus.Completed}>مكتمل</option>
                <option value={StudentStatus.Exited}>غادر</option>
              </Select>
            </Flex>
          ))}
        </VStack>
      </Box>

      {/* Rooms Management Section */}
      <Box w="100%" bg="white" p={4} borderRadius="md" boxShadow="lg">
        <Heading size="md" color="kfupm.petrol" mb={4} textAlign="center">
          إدارة الغرف
        </Heading>
        <Flex justify="space-around" flexWrap="wrap">
          {rooms.map((room) => (
            <Box
              key={room.id}
              bg={
                room.status === RoomStatus.Available ? "green.400" : "red.400"
              }
              color="white"
              p={4}
              borderRadius="md"
              w="30%"
              minH="150px"
              boxShadow="lg"
              textAlign="center"
            >
              <Heading size="md">غرفة {room.room_name}</Heading>
              <Text>
                السعة الحالية: {room.current_occupancy} / {room.capacity}
              </Text>
              <Select
                mt={4}
                value={room.status}
                onChange={(e) =>
                  updateRoomStatus(room.id, e.target.value as RoomStatus)
                }
              >
                <option value={RoomStatus.Available}>متاحة</option>
                <option value={RoomStatus.Full}>ممتلئة</option>
              </Select>
              <VStack spacing={2} mt={4}>
                {room.student_ids.map((studentId) => (
                  <Flex
                    key={studentId}
                    align="center"
                    justify="space-between"
                    w="100%"
                  >
                    <Text fontSize="md">{studentId}</Text>
                    <Button
                      colorScheme="red"
                      size="xs"
                      onClick={() => removeRoomStudent(room.id, studentId)}
                    >
                      إزالة
                    </Button>
                  </Flex>
                ))}
              </VStack>
            </Box>
          ))}
        </Flex>
      </Box>
    </VStack>
  );
};

export default Admin;
