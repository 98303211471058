// /services/dataService.ts
import { supabase } from "./supabaseClient";

// Fetch all entries from a specified table
export const fetchTableData = async (table: string) => {
  const { data, error } = await supabase.from(table).select("*");
  if (error) throw error;
  return data;
};
export const monitorRoomQueue = () => {
  const interval = 10000; // Check every 10 seconds (adjust as needed)

  setInterval(async () => {
    try {
      // Fetch all available rooms with open capacity
      const rooms = await fetchTableData("rooms");
      const emptyRooms = rooms.filter(
        (room: any) => room.current_occupancy < room.capacity
      );

      // Fetch all students waiting in the queue
      const { data: waitingStudents, error } = await supabase
        .from("queue")
        .select("*")
        .eq("status", StudentStatus.Waiting);

      if (error) throw error;

      // Attempt to add waiting students to empty rooms
      if (
        emptyRooms.length > 0 &&
        waitingStudents &&
        waitingStudents.length > 0
      ) {
        await assignWaitingStudentToRoom();
        console.log("Checked and attempted to assign students to empty rooms.");
      }
    } catch (error) {
      console.error("Error in monitoring room queue:", error);
    }
  }, interval);
};

// Add a new entry to a specified table
export const addEntryToTable = async (table: string, entryData: object) => {
  const { data, error } = await supabase.from(table).insert(entryData);
  if (error) throw error;
  return data || []; // Ensure it always returns an array
};

// Update an entry in a specified table by ID
export const updateTableEntry = async (
  table: string,
  id: number | string,
  updatedData: object
) => {
  const { data, error } = await supabase
    .from(table)
    .update(updatedData)
    .eq("id", id);

  if (error) throw error;
  return data;
};

// Remove an entry from a specified table by ID
export const removeEntryFromTable = async (table: string, id: number) => {
  const { data, error } = await supabase.from(table).delete().eq("id", id);
  if (error) throw error;
  return data;
};

export const removeQueueEntry = async (stid: string) => {
  try {
    let { data, error } = await supabase
      .from("queue")
      .delete()
      .eq("stid", stid)
      .limit(1)
      .single();

    if (error) throw error;

    if (data) {
      const queueStudent = data as QueueStudent;
      if (queueStudent.room_id !== undefined) {
        await removeStudentFromRoom(
          queueStudent.room_id,
          Number(queueStudent.id)
        );
      }
    }

    return data;
  } catch (error) {
    console.error("Failed to remove student from queue:", error);
    throw error;
  }
};
interface QueueStudent {
  id: string;
  stid: string;
  room_id?: number; // room_id might be undefined, so make it optional
}

// Enum for student status
export enum StudentStatus {
  Waiting = "waiting",
  InRoom = "in_room",
  Completed = "completed",
  Exited = "exited",
}
export enum RoomStatus {
  Available = "available",
  Full = "full",
}

export enum LogAction {
  Queued = "queued",
  AssignedToRoom = "assigned_to_room",
  Completed = "completed",
  Exited = "exited",
}

// Function to add a student to the queue without assigning a room, then attempt room assignment
export const addStudentToQueue = async (stid: string) => {
  try {
    const newStudent = { stid, status: StudentStatus.Waiting, room_id: null };
    const { data, error } = await supabase.from("queue").insert(newStudent);
    if (error) throw new Error("Failed to add student to the queue.");

    await addLogEntry(LogAction.Queued, stid);

    // Attempt to assign a waiting student to a room if a vacancy is available
    await assignWaitingStudentToRoom();

    return data;
  } catch (error) {
    console.error("Failed to add student to queue:", error);
    throw error; // Rethrow to handle it in the component
  }
};

// Function to add a log entry to the logs table with optional stid and room_id
export const addLogEntry = async (
  action: string,
  stid?: string,
  room_id?: number
) => {
  try {
    const logEntry = {
      action,
      stid: stid || null,
      room_id: room_id || null,
      timestamp: new Date().toISOString(),
    };
    const { data, error } = await supabase.from("logs").insert(logEntry);
    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Failed to add log entry:", error);
    throw error;
  }
};

// Function to assign a waiting student to an available room
export const assignWaitingStudentToRoom = async () => {
  try {
    const rooms = await fetchTableData("rooms");
    const sortedRooms = rooms
      .filter((room: any) => room.current_occupancy < room.capacity) // Filter rooms with available capacity
      .sort((a: any, b: any) => a.current_occupancy - b.current_occupancy); // Sort by current occupancy
    const availableRoom = sortedRooms[0]; // Choose the room with least occupancy

    if (availableRoom.student_ids.length >= availableRoom.capacity) return null;

    // Step 2: Find the first student with status "waiting"
    const waitingStudents = await supabase
      .from("queue")
      .select("*")
      .eq("status", StudentStatus.Waiting)
      .limit(1);

    if (!waitingStudents.data || waitingStudents.data.length === 0) return null; // No waiting students

    const student = waitingStudents.data[0];

    // Step 3: Update student to assign to room, change status, and add in_room_at timestamp
    await updateTableEntry("queue", student.id, {
      status: StudentStatus.InRoom,
      room_id: availableRoom.id,
    });

    await addLogEntry(LogAction.AssignedToRoom, student.stid, availableRoom.id);

    const updatedOccupancy = availableRoom.current_occupancy + 1;
    const updatedStudentIds = [
      ...(availableRoom.student_ids || []),
      student.stid,
    ];
    const updatedRoomStatus =
      updatedOccupancy === availableRoom.capacity
        ? RoomStatus.Full
        : RoomStatus.Available;

    await updateTableEntry("rooms", availableRoom.id, {
      current_occupancy: updatedOccupancy,
      student_ids: updatedStudentIds,
      status: updatedRoomStatus,
    });

    return student;
  } catch (error) {
    console.error("Failed to assign waiting student to room:", error);
    throw error;
  }
};

export const changeStudentStatus = async (
  studentId: number,
  status: StudentStatus
) => {
  try {
    const { data, error } = await supabase
      .from("queue")
      .update({ status })
      .eq("id", studentId)
      .select(); // Ensure data is selected

    if (error) throw error;

    if (!data || data.length === 0) throw new Error("No student data found");
    const student = data[0] as { stid: string };

    return data;
  } catch (error) {
    console.error("Failed to change student status:", error);
    throw error;
  }
};

export const removeStudentFromRoom = async (
  roomId: number,
  studentId: number
) => {
  try {
    // Fetch the room data
    const room = await fetchTableData("rooms").then((rooms) =>
      rooms.find((r: any) => r.id === roomId)
    );

    if (!room) throw new Error("Room not found");

    // Update occupancy and remove the student ID from student_ids array
    let updatedOccupancy = room.current_occupancy - 1;
    if (updatedOccupancy < 0) updatedOccupancy = 0;

    const updatedStudentIds = room.student_ids.filter(
      (id: string) => id.toString() !== studentId.toString()
    );

    const updatedRoomStatus =
      updatedOccupancy < 3 ? RoomStatus.Available : room.status;

    await updateTableEntry("rooms", roomId, {
      current_occupancy: updatedOccupancy,
      student_ids: updatedStudentIds,
      status: updatedRoomStatus,
    });
    await updateQueueStatusByStid(
      studentId.toString(),
      StudentStatus.Completed
    );

    // Log the action as "Exited" in the logs table
    await addLogEntry(LogAction.Completed, studentId.toString(), roomId);
    await assignWaitingStudentToRoom();
  } catch (error) {
    console.error("Failed to remove student from room:", error);
    throw error;
  }
};

export const updateQueueStatusByStid = async (
  stid: string,
  updatedStatus: StudentStatus
) => {
  const { data, error } = await supabase
    .from("queue")
    .update({ status: updatedStatus })
    .eq("stid", stid);

  if (error) throw error;
  return data;
};
