import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Tag,
  Heading,
  Text,
  VStack,
  HStack,
  Badge,
} from "@chakra-ui/react";
import { useDataStore } from "./stores/useDataStore";
import { StudentStatus } from "../../services/dataService";
import studentData from "../../data/data.json";
import theme from "../../theme/Theme";
import useSound from "use-sound";
import { monitorRoomQueue } from "../../services/dataService";
const ringSound = require("./sounds/ring.mp3");

type College = "ES" | "IM" | "ED" | "ARC" | "CS" | "CM" | "PG" | "Master";
const CollegeColors = {
  ES: theme.colors.kfupm.engineering,
  IM: theme.colors.kfupm.businessSchool,
  ED: theme.colors.kfupm.design,
  ARC: theme.colors.kfupm.design,
  CS: theme.colors.kfupm.computing,
  CM: theme.colors.kfupm.chemicals,
  PG: theme.colors.kfupm.petroleumEngineering,
  Master: theme.colors.kfupm.graduate,
};

const Screen = () => {
  const { rooms, queue, fetchRooms, fetchQueue, initializeSubscriptions } = useDataStore();
  const [timer, setTimer] = useState(0);
  const [play] = useSound(ringSound);

  useEffect(() => {
    fetchRooms();
    fetchQueue();
    initializeSubscriptions();
    monitorRoomQueue();
    const interval = setInterval(() => {
      setTimer((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [fetchRooms, fetchQueue, initializeSubscriptions]);

  useEffect(() => {
    if (queue.some((student) => student.status === StudentStatus.Waiting)) {
      play(); 
    }
  }, [queue, play]);

  const calculateTimeSpent = (inRoomAt: string) => {
    const inRoomDate = new Date(inRoomAt);
    const now = new Date();
    const diffMs = now.getTime() - inRoomDate.getTime();
    const minutes = Math.floor(diffMs / 60000) - 180;
    const seconds = Math.floor((diffMs % 60000) / 1000);
    return `${minutes}m ${seconds}s`;
  };

  const findStudentData = (stid: string) => {
    return studentData.find((student) => student.Student_ID === Number(stid));
  };

  const sortedRooms = [...rooms].sort((a, b) => Number(a.id) - Number(b.id));

  const isQueueEmpty = queue.every(
    (student) => student.status === StudentStatus.Completed
  );

  return (
    <VStack spacing={8} p={6} bg="gray.100" minH="100vh">
      <Heading size="lg" color="kfupm.green" mb={4}>
        شاشة الغرف وقائمة الانتظار
      </Heading>

      <Flex w="100%" justify="space-around" flexWrap="wrap">
        {sortedRooms.map((room) => (
          <Box
            key={room.id}
            bg={room.status === "available" ? "green.400" : "red.400"}
            color="white"
            p={4}
            borderRadius="md"
            w={["100%", "30%", "30%"]}
            minH="150px"
            boxShadow="lg"
            textAlign="center"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Heading size="md">غرفة {room.room_name}</Heading>
            <Text>
              السعة الحالية: {room.current_occupancy} / {room.capacity}
            </Text>
            <VStack spacing={2} mt={4}>
              {room.student_ids.length > 0 ? (
                room.student_ids.map((studentId, idx) => {
                  const student = queue.find(
                    (q) => q.stid === studentId.toString()
                  );
                  const studentInfo = findStudentData(studentId.toString());
                  const collegeColor = studentInfo
                    ? CollegeColors[studentInfo.College as College] || "gray"
                    : "gray";

                  return (
                    <Box
                      key={idx}
                      bg={collegeColor}
                      p={3}
                      borderRadius="md"
                      color="white"
                      boxShadow="lg"
                      textAlign="center"
                    >
                      <Text fontSize="lg" fontWeight="bold">
                        {studentInfo ? studentInfo.Name_in_Arabic : studentId}
                      </Text>
                      <Text fontSize="sm" opacity={0.8}>
                        رقم الطالب: {studentId}
                      </Text>
                      <Text mt={2} fontSize="md">
                        {student?.in_room_at
                          ? calculateTimeSpent(student.in_room_at)
                          : <Tag colorScheme="yellow">توجه الى الغرفة</Tag>}
                      </Text>
                    </Box>
                  );
                })
              ) : (
                <Text fontSize="sm">لا يوجد طلاب حاليا</Text>
              )}
            </VStack>
          </Box>
        ))}
      </Flex>

      <Box w="100%" bg="gray.50" p={4} borderRadius="md" boxShadow="lg">
        <Heading size="md" color="kfupm.petrol" mb={4} textAlign="center">
          قائمة الانتظار
        </Heading>
        <HStack wrap="wrap" spacing={4} justify="center">
          {queue
            .filter((student) => student.status === StudentStatus.Waiting)
            .map((student, index) => {
              const studentInfo = findStudentData(student.stid);
              const collegeColor = studentInfo
                ? CollegeColors[studentInfo.College as College] || "gray"
                : "gray";

              return (
                <HStack key={student.id}>
                  <Box
                    bg={collegeColor}
                    p={3}
                    borderRadius="md"
                    boxShadow="lg"
                    textAlign="center"
                  >
                    <Text fontSize="lg">
                      {studentInfo ? studentInfo.Name_in_Arabic : student.stid}
                    </Text>
                    <Text fontSize="sm" opacity={0.8}>
                      رقم الطالب: {student.stid}
                    </Text>
                    <Tag color="white" borderRadius="full" size="md" mt={2}>
                      {studentInfo ? studentInfo.College : "N/A"}
                    </Tag>
                    {index < 6 && (
                      <Badge colorScheme="yellow" fontSize="0.8em" mt={2}>
                        اجهز!
                      </Badge>
                    )}
                  </Box>
                </HStack>
              );
            })}
          {isQueueEmpty && (
            <Text color="black">لا يوجد طلاب في قائمة الانتظار</Text>
          )}
        </HStack>
      </Box>
    </VStack>
  );
};

export default Screen;
