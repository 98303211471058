// /features/admin/stores/useAdminStore.ts
import { create } from "zustand";
import {
  fetchTableData,
  updateTableEntry,
  removeEntryFromTable,
} from "../../../services/dataService";
import { StudentStatus, RoomStatus } from "../../../services/dataService";

interface Room {
  id: string;
  room_name: string;
  current_occupancy: number;
  capacity: number;
  student_ids: string[];
  status: string;
}

interface Student {
  id: string;
  stid: string;
  status: string;
  in_room_at?: string;
}

interface AdminStore {
  rooms: Room[];
  queue: Student[];
  loadRooms: () => Promise<void>;
  loadQueue: () => Promise<void>;
  updateQueueStatus: (
    studentId: string,
    status: StudentStatus
  ) => Promise<void>;
  updateRoomStatus: (roomId: string, status: RoomStatus) => Promise<void>;
  removeRoomStudent: (roomId: string, studentId: string) => Promise<void>;
}

export const useAdminStore = create<AdminStore>((set, get) => ({
  rooms: [],
  queue: [],

  loadRooms: async () => {
    const rooms = await fetchTableData("rooms");
    set({ rooms });
  },

  loadQueue: async () => {
    const queue = await fetchTableData("queue");
    set({ queue });
  },

  updateQueueStatus: async (studentId, status) => {
    await updateTableEntry("queue", studentId, { status });
    get().loadQueue();
  },

  updateRoomStatus: async (roomId, status) => {
    await updateTableEntry("rooms", roomId, { status });
    get().loadRooms();
  },

  removeRoomStudent: async (roomId, studentId) => {
    const room = get().rooms.find((r) => r.id === roomId);
    if (!room) return;

    const updatedOccupancy = room.current_occupancy - 1;
    const updatedStudentIds = room.student_ids.filter((id) => id !== studentId);
    const updatedRoomStatus =
      updatedOccupancy === 0 ? RoomStatus.Available : room.status;

    await updateTableEntry("rooms", roomId, {
      current_occupancy: updatedOccupancy,
      student_ids: updatedStudentIds,
      status: updatedRoomStatus,
    });

    get().loadRooms(); // Refresh rooms data
  },
}));
