// /features/screen/useDataStore.ts
import { create } from "zustand";

import { fetchTableData } from "../../../services/dataService";
import { subscribeToTable } from "../../../services/realtimeService";

export interface Room {
  id: string;
  room_name: string;
  status: string;
  student_ids: string[];
  current_occupancy: number;
  capacity: number;
}

export interface Student {
  id: string;
  stid: string;
  status: string;
  in_room_at?: string;
}

export interface DataStore {
  rooms: Room[];
  queue: Student[];
  lastFetched: { rooms: number; queue: number };
  fetchRooms: () => Promise<void>;
  fetchQueue: () => Promise<void>;
  initializeSubscriptions: () => void;
}

export const useDataStore = create<DataStore>((set) => ({
  rooms: [],
  queue: [],
  lastFetched: { rooms: 0, queue: 0 },

  fetchRooms: async () => {
    const now = Date.now();
    set((state) => {
      if (now - state.lastFetched.rooms < 10000) return state; 
      return state;
    });
    const fetchedRooms = await fetchTableData("rooms");
    set({
      rooms: fetchedRooms,
      lastFetched: { rooms: now, queue: Date.now() },
    });
  },

  fetchQueue: async () => {
    const now = Date.now();
    set((state) => {
      if (now - state.lastFetched.queue < 10000) return state;
      return state;
    });
    const fetchedQueue = await fetchTableData("queue");
    set({
      queue: fetchedQueue,
      lastFetched: { rooms: Date.now(), queue: now },
    });
  },

  initializeSubscriptions: () => {
    subscribeToTable("rooms", async () => {
      await useDataStore.getState().fetchRooms();
    });
    subscribeToTable("queue", async () => {
      await useDataStore.getState().fetchQueue();
    });
  },
}));
