// /services/realtimeService.ts
import { supabase } from "./supabaseClient";

export const subscribeToTable = (
  table: string,
  callback: (payload: any) => void
) => {
  const channel = supabase
    .channel(`${table.toLowerCase()}`)
    .on(
      "postgres_changes",
      { event: "*", schema: "public", table: table.toLowerCase() },
      callback
    )
    .subscribe();

  const unsubscribe = () => {
    supabase.removeChannel(channel);
  };

  return { channel, unsubscribe };
};
