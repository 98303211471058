// /features/registrar/useRegistrarStore.ts
import { create } from "zustand";
import { fetchTableData } from "../../services/dataService";

interface Student {
  id: string;
  stid: string;
  status: string;
  room_id?: number;
}

interface RegistrarStore {
  queue: Student[];
  lastFetched: number;
  loadQueue: () => Promise<void>;
}

export const useRegistrarStore = create<RegistrarStore>((set) => ({
  queue: [],
  lastFetched: 0,

  // Fetch queue data if not recently fetched
  loadQueue: async () => {
    const now = Date.now();
    set((state) => {
      if (now - state.lastFetched < 10000) return state; // Cache duration: 10 seconds
      return state;
    });
    const fetchedQueue = await fetchTableData("queue");
    set({ queue: fetchedQueue, lastFetched: now });
  },
}));
